<template>
  <button class="rank" @click="navigateToTops">
    <div class="eclipse-rank"></div>
    <div class="rank-img">
      <img id="rank-image" :src="rankImagePath">
    </div>
    <div class="rank-info">
      <a id="rank-name">{{ rankName }}</a>
      <div class="rank-progress" :style="{ backgroundColor: rankColorEmpty }">
        <div class="rank-progress-bar" :style="{ width: progress + '%', backgroundColor: rankColorFull }"></div>
      </div>
    </div>
    <div class="angle-right">
      <!-- Используем компонент Icon -->
      <Icon style="height: 5em; width: 2em" icon="ep:arrow-right-bold" />
    </div>
  </button>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'RankStatusComponent',
  components: {
    Icon,  // Регистрируем компонент Icon
  },
  props: ['rankName', 'rankColorEmpty', 'rankColorFull', 'progress', 'userId'],
  data() {
    return {
      rankImages: {
        'Rookie': require('@/assets/img/ranks/rookie.png'),
        'Silver': require('@/assets/img/ranks/silver.png'),
        'Skilled': require('@/assets/img/ranks/skilled.png'),
        'Advanced': require('@/assets/img/ranks/advanced.png'),
        'Expert': require('@/assets/img/ranks/expert.png')
      }
    }
  },
  computed: {
    rankImagePath() {
      return this.rankImages[this.rankName];
    }
  },
  methods: {
    navigateToTops() {
      this.$router.push({ path: 'tops', query: { r: this.userId } });
    }
  }
}
</script>
