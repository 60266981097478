<template>
  <div class="non-mobile-message">
    <h6>This website is only accessible on mobile devices and through Telegram Mini Apps</h6>
    <p>If you want to log in to the game, please log in from your phone.</p>
    <img src="@/assets/img/qr.png" alt="qr-code-rikcoin">
    <a href="https://t.me/rikcoinbot">@rikcoinbot</a>
  </div>
</template>

<script>
export default {
  name: 'MobileMessage'
}
</script>