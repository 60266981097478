<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@font-face {
    font-family: "Inter";
    src: url("@/assets/font/Inter.ttf");
}

body {
    margin: 0;
    height: 100vh;
    overflow: hidden;
    background: url("@/assets/img/body.svg") no-repeat center center fixed;
    background-size: cover;
    background-color: black;
    font-family: "Inter";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none; 
    -moz-user-drag: none; 
    -o-user-drag: none; 
}

.global {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 25px;
}

.global .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.global .info a {
    color: white;
    font-weight: 600;
    font-size: 36px;
}

.global .info p {
    margin: 0;
    color: #CBCBCB;
}

.rank {
    display: flex;
    position: relative;
    width: 350px;
    height: 70px;
    background-color: #171719;
    flex-wrap: nowrap;
    border-radius: 20px;
    overflow: hidden;
    border: none;
    padding: 0;
    align-items: center;
}

@media screen and (max-width: 380px) {
    .global {
        top: 20px;
    }

    .rank {
        width: 290px;
    }
}

.rank .rank-img {
    display: flex;
    width: 70px;
    height: 70px;
    background-color: #252528;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.rank-img img {
    width: 50px;
}

.eclipse-rank {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 100px;
    top: -200px;
    background: #EEEEEE;
    filter: blur(76.7px);
}

.rank-info {
    display: flex;
    position: relative;
    margin-left: 20px;
    color: white;
    gap: 5px;
    flex-direction: column;
}

.rank-info a {
    display: flex;
    font-weight: 600;
    font-size: 20px;
    color: #9e9e9e;
}

.rank-info .rank-progress {
    height: 5px;
    width: 150px;
    background: #2F2F30;
    border-radius: 20px;
}

.angle-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #2F2F30;
}

.coins {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 50px;
}

.coins a {
    color: white;
    font-size: 36px;
    font-weight: 600;
}

.coins p {
    color: #CBCBCB;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
}

.clicker img {
    width: 320px;
    
}

@media screen and (max-height: 730px) {
    .clicker img {
        width: 260px;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 380px) {
    .clicker img {
        width: 250px;
    }
}

.one-more-hud {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 10px;
}

.energy-progress a {
    color: #ECD450;
    font-weight: 700;
    font-size: 19px;
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
}

.energy-progress img {
    width: 26px;
}

.energy-progress .progress {
    position: relative;
    height: 5px;
    width: 350px;
    background: #9A6811;
    border-radius: 20px;
    overflow: hidden;
}

@media screen and (max-width: 380px) {
    .energy-progress .progress {
        width: 290px;
    }
}

.progress .progress-bar {
    height: 5px;
    width: 100%;
    border-radius: 20px;
    background: #ECD450;
}

.menu {
    position: relative;
    margin-top: 10px;
    background: #171719;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    overflow: hidden;
}

.menu .button {
    width: 80px;
    height: 80px;
    background: #242424;
    border: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 5;
    font-family: "Inter";
}

.menu .button img {
    width: 50px;
    height: 50px;
}

.menu .button a {
    color: #DEBD38;
    font-weight: 600;
    font-size: 15px;
}

@media screen and (max-width: 380px) {
    .menu .button {
        width: 60px;
        height: 60px;
        border-radius: 15px;
    }

    .menu .button img {
        width: 40px;
        height: 40px;
        border-radius: 15px;
    }

    .menu .button a {
        font-size: 12px;
    }
}

.menu .button:hover {
    background: #333333;
}

.menu .button.active {
    background: #333333;
}

.menu .eclipse-menu {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 120px;
    top: -240px;
    background: #EEEEEE;
    filter: blur(76.7px);
}


.floating-text {
    position: absolute;
    color: white;
    font-weight: bold;
    animation: float-up 1s ease-out forwards;
    pointer-events: none;
}

@keyframes float-up {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.block-info {
    position: relative;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    gap: 10px;
}

.block-info .block {
    display: flex;
    background: #171719;
    border-radius: 20px;
    padding: 13px 6px;
    gap: 10px;
}

.block-info .block .block-img img {
    width: 60px;
}

.block-info .block .block-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.block-info .block .block-text a {
    color: white;
    font-weight: 600;
    font-size: 16px;
}

.block-info .block .block-text p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #78787C;
    font-weight: 500;
    font-size: 16px;
}

.block-info .block .block-text p img {
    width: 15px;
}

@media screen and (max-width: 380px) {
    .block-info .block .block-img img {
        width: 40px;
    }

    .block-info .block .block-text a {
        font-size: 14px;
    }
}

.addfren {
    display: flex;
    margin-top: 40px;
}

.addfren .button {
    position: relative;
    display: flex;
    border-radius: 20px;
    padding: 25px 110px;
    background: #F58015;
    gap: 10px;
    border: none;
    font-size: 23px;
    color: #FFFFFF;
    font-weight: 600;
    box-shadow: 0px 70px 73px rgba(248, 145, 26, 0.01),
        0px 40px 61px rgba(248, 145, 26, 0.05),
        0px 25px 45px rgba(248, 145, 26, 0.09),
        0px 5px 25px rgba(248, 145, 26, 0.1);
    overflow: hidden;
    text-decoration: none;
    animation: pulse 1.5s infinite;
    font-family: "Inter";
}

.addfren .button .eclipse-fren {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 120px;
    top: -100px;
    filter: blur(76.7px);
    mix-blend-mode: overlay;
    background: white;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 380px) {
    .addfren .button {
        padding: 20px 95px;
        font-size: 20px;
    }
}

.list-frens {
    margin: 25px auto 120px;
}

.list-frens-title a {
    color: white;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    margin-bottom: 15px;
    gap: 5px;
}

.list-frens a span {
    color: #F8941B;
}

.block-fren {
    display: flex;
    background: #171719;
    border-radius: 20px;
    padding: 10px;
    width: 350px;
    height: 60px;
    gap: 20px;
    margin-bottom: 6px;
}

.block-fren .block-fren-img {
    display: flex;
    border-radius: 15px;
    background: #2B2B2F;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 60px;
}

.block-fren-img img {
    width: 60px;
}

.block-fren-img a {
    display: flex;
    width: 60px;
    font-size: 32px;
    color: #484848;
    font-weight: 900;
    justify-content: center;
    align-items: center;
}

.block-fren .block-fren-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-fren-info a {
    font-weight: 600;
    color: #696970;
    font-size: 16px;
    margin-bottom: 0;
}

.block-fren-cash {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-fren-cash a {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: #F8911A;
    align-items: center;
    gap: 5px;
}

.block-fren-cash a img {
    height: 20px;
}

.block-fren .block-fren-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.block-fren .block-fren-text a {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.block-fren .block-fren-text p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #6A90B2;
    font-weight: 500;
    font-size: 16px;
}

.block-fren .block-fren-text p img {
    width: 15px;
}

.block-fren-user {
    display: flex;
    width: 270px;
    justify-content: space-between;
}

.block-fren-user .information {
    display: flex;
    gap: 10px;
}

@media screen and (max-width: 380px) {

    .list-frens-title a {
        font-size: 16px;
    }
    .block-fren-img img {
        width: 50px;
    }


    .block-fren {
        width: 280px;
        height: 50px;
    }

    .block-fren .block-fren-text a {
        font-size: 16px;
    }
    .block-fren-cash a {
        font-size: 18px;
    }
    .block-fren .block-fren-text p {
        font-size: 14px;
    }

    .block-fren-img a {
        font-size: 26px;
        width: 65px;
    }

}

.boosts {
    margin: 25px auto;
}

.boosts-title a {
    color: #F99C1D;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    margin-bottom: 15px;
    gap: 5px;
}

.block-boosts {
    display: flex;
    background: #171719;
    border-radius: 20px;
    padding: 10px;
    width: 355px;
    height: 60px;
    gap: 20px;
    margin-bottom: 6px;
}

.block-boosts .input {
    display: flex;
    justify-content: space-between;
    width: 350px;
}

.input input {
    display: flex;
    width: 200px;
    background: transparent;
    border: none;
    outline: none;
    font-family: "Inter";
    font-size: 16px;
    color: #78787C;
}

.input button {
    width: 130px;
    border-radius: 20px;
    background: #F58015;
    border: none;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: relative;
    overflow: hidden;
}

.input button:active {
    background: #b65f10;
}

.eclipse-boosts {
    position: absolute;
    width: 150px;
    height: 150px;
    left: -53px;
    top: 51px;
    background: #EEEEEE;
    filter: blur(25.7px);
    mix-blend-mode: overlay;
}

.boosts-button {
    display: flex;
    position: relative;
    width: 375px;
    height: 80px;
    background-color: #171719;
    flex-wrap: nowrap;
    border-radius: 20px;
    overflow: hidden;
    border: none;
    padding: 0;
    align-items: center;
    font-family: "Inter";
    margin-bottom: 5px;
}

.boosts-button-img {
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.boosts-button-img img {
    width: 50px;
    border-radius: 15px;
}

.boosts-button-info {
    display: flex;
    position: relative;
    margin-left: 20px;
    color: white;
    flex-direction: column;
}

.boosts-button-info a {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    color: white;
}


.boosts-button-info .information a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #78787C;
    font-weight: 500;
    font-size: 18px;
}

.boosts-button-info h6 {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    color: white;
    margin: 0;
}

.boosts-button-info .information img {
    width: 15px;
}

.boosts-button-info .information p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #78787C;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.boosts-button-info .information p img {
    width: 15px;
}

.boosts-button-info .information {
    display: flex;
    gap: 5px;
    align-items: center;
}

@media screen and (max-width: 380px) {
    .boosts-button {
        width: 320px;
    }

    .block-boosts {
        width: 300px;
    }

    .input button {
        width: 100px;
    }

    .boosts-button-info a {
        font-size: 14px;
    }

    .boosts-button-info .information a {
        font-size: 14px;
    }

    .boosts-button-info h6 {
        font-size: 14px;
    }
    .boosts-button-info .information p {
        font-size: 14px;
    }
}


.block-info-stats {
    position: relative;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    gap: 10px;
    width: 350px;
}

.block-stats {
    display: flex;
    background: #171719;
    border-radius: 20px;
    padding: 30px 40px;
    gap: 10px;
}

.block-stats-text {
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
}

.block-stats-text a {
    display: flex;
    color: white;
    font-weight: 600;
    font-size: 17px;
    align-items: center;
}

.block-stats-text p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #FF962B;
    font-weight: 500;
    font-size: 20px;
}

.block-stats-text p img {
    width: 20px;
}

@media screen and (max-width: 380px) {
    .block-info-stats {
    width: 320px;
}       
}

.block-info-top {
    position: relative;
}

.block-top {
    position: relative;
    overflow: hidden;
    display: flex;
    background: #012A67;
    border-radius: 20px;
    padding: 30px;
    gap: 10px;
    box-shadow: 0 0 50px #012A67;
}

.block-img-top img {
    width: 200px;
}


.eclipse-tops {
    position: absolute;
    width: 150px;
    height: 150px;
    left: -20px;
    top: -90px;
    filter: blur(76.7px);
    mix-blend-mode: overlay;
    background: white;
}

.eclipse-tops2 {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 144px;
    top: 250px;
    filter: blur(76.7px);
    mix-blend-mode: overlay;
    background: white;
}

.angle-tops {
    color: #2F2F30;
    font-size: 25px;
}

.rank-status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.information-rank {
    margin-top: 20px;
}

.name-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.progress-rank .progress-info {
    display: flex;
    justify-content: space-between;
}

.progress-info a {
    font-size: 18px;
    color: white;
}

.progress-rank-line {
    height: 10px;
    border-radius: 15px;
    margin-top: 10px;
    overflow: hidden;
}

@media screen and (max-width: 380px) {
    .block-img-top img {
        width: 150px;
    }

    .angle-tops {
        font-size: 20px;
    }

    .name-rank {
        font-size: 25px;
    }

    .progress-info a {
        font-size: 15px;
    }
    
}

.notification-container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.notification {
    background: #171719;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 10px;
    opacity: 1;
    font-size: 18px;
    color: #696970;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    align-items: center;
    gap: 10px;
}

.notification.fade {
    opacity: 0;
}

.notification-image {
    width: 20px;
    height: 20px;
}

.rank-progress {
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #CCCCCC;
    /* Цвет по умолчанию, если не установлен в PHP */
    overflow: hidden;
}

.rank-progress-bar {
    height: 100%;
    background-color: none;
    transition: width 0.5s ease-in-out;
}

.non-mobile-message {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
}

.non-mobile-message h6 {
    display: flex;
    font-size: 26px;
    font-weight: 600;
    padding: 0 50px;
    text-align: center;
    color: white;
    margin: 0;
}

.non-mobile-message p {
    display: flex;
    padding: 0 50px;
    font-size: 14px;
    margin: 50px 10px;
    text-align: center;
    color: #CBCBCB;
}

.non-mobile-message img {
    width: 206px;
}

.non-mobile-message a {
    font-size: 20px;
    margin-top: 5px;
    font-weight: 600;
    text-decoration: none;
    color: white;
}

.container {
    width: 80px;
    height: 100px;
    margin: 100px auto;
    margin-top: calc(100vh / 2 - 50px);
}

.loading {
    position: relative;
    box-sizing: border-box;
    float: left;
    margin: 0 10px 10px 0;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background: #FFF;
}

.loading:nth-child(4n+1) {
    animation: wave 2s ease .0s infinite;
}

.loading:nth-child(4n+2) {
    animation: wave 2s ease .2s infinite;
}

.loading:nth-child(4n+3) {
    animation: wave 2s ease .4s infinite;
}

.loading:nth-child(4n+4) {
    animation: wave 2s ease .6s infinite;
    margin-right: 0;
}

@keyframes wave {
    0% {
        top: 0;
        opacity: 1;
    }

    50% {
        top: 30px;
        opacity: .2;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}
</style>