<template>
  <div class="global">
    <NotificationComponent ref="notification" />

    <RankStatusComponent
      :rank-name="rankName"
      :rank-color-empty="rankColorEmpty"
      :rank-color-full="rankColorFull"
      :progress="progress"
      :user-id="userId"
    />

    <BalanceComponent :coins="coins" />

    <ClickerComponent
      v-model:coins="coins"
      v-model:energy="newEnergy"
      :energy-limit="energyLimit"
      :multitap-coin="multitapCoin"
      :user-id="userId"
      :rank-name="rankName"
      :r-speed="r_speed"
    />
  </div>

  <div class="one-more-hud">
    <EnergyProgressComponent :current-energy="newEnergy" :total-energy="energyLimit" />
    <MenuComponent />
  </div>
</template>

<script>
import NotificationComponent from '../components/NotificationComponent.vue'
import RankStatusComponent from '../components/RankStatusComponent.vue'
import ClickerComponent from '../components/ClickerComponent.vue'
import EnergyProgressComponent from '../components/EnergyProgressComponent.vue'
import MenuComponent from '../components/MenuComponent.vue'
import BalanceComponent from '../components/BalanceComponent.vue'

export default {
  name: 'HomeView',
  components: {
    NotificationComponent,
    RankStatusComponent,
    ClickerComponent,
    EnergyProgressComponent,
    MenuComponent,
    BalanceComponent
  },
  data() {
    return {
      coins: 0,
      newEnergy: 0,
      energyLimit: 0,
      userId: '',
      rankName: '',
      rankColorEmpty: '',
      rankColorFull: '',
      progress: 0,
      multitapCoin: 0.00001,
      r_speed: 3
    }
  },
  async created() {
    this.fetchUserData()
  },
  methods: {
    async fetchUserData() {
      try {
        const response = await this.$axios.get('/home.php')
        if (response.data.error) {
          console.error(response.data.error)
          this.showNotification(response.data.error)
        } else {
          this.coins = response.data.coins
          this.newEnergy = response.data.current_energy
          this.energyLimit = response.data.energy_limit
          this.userId = response.data.user_id
          this.rankName = response.data.rank_name
          this.rankColorEmpty = response.data.rank_color_empty
          this.rankColorFull = response.data.rank_color_full
          this.progress = response.data.progress
          this.multitapCoin = Number(response.data.multitap_coin)
          this.r_speed = Number(response.data.r_speed)
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        this.showNotification('Error fetching user data')
      }
    },
    showNotification(message) {
      this.$refs.notification.show(message)
    }
  }
}
</script>
