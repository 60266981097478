<template>
    <div class="global" style="position: relative">
        <NotificationComponent ref="notification" />

        <div class="info">
            <a>Invite Friends</a>
            <p>You, and your friends take bonus</p>
        </div>

        <div class="block-info">
            <div class="block" v-for="block in bonusBlocks" :key="block.id">
                <div class="block-img">
                    <img :src="block.imgSrc" />
                </div>
                <div class="block-text">
                    <a>{{ block.title }}</a>
                    <p><img src="@/assets/img/clicker.png" />{{ block.bonusText }}</p>
                </div>
            </div>
        </div>

        <div class="addfren">
            <button class="button" @click="inviteFriend">
                <div class="eclipse-fren"></div>
                Invite friend
            </button>
        </div>

        <div class="list-frens" id="friends-list">
            <div class="list-frens-title">
                <a>List of your friends <span>({{ totalFriends }})</span></a>
            </div>
            <div v-for="friend in friends" :key="friend.tg_id" class="block-fren" :data-tg-id="friend.tg_id">
                <div class="block-fren-img">
                    <img v-if="isValidPhotoUrl(friend.photo)" :src="friend.photo" alt="Friend Photo" />
                    <a v-else>RC</a>
                </div>
                <div class="block-fren-user">
                    <div class="block-fren-text">
                        <a>{{ friend.username }}</a>
                        <div class="information">
                            <p><Icon icon="gravity-ui:persons" />{{ friend.ref_count }}</p>
                            <p style="color:#FF962B">
                                <img src="@/assets/img/clicker.png" />{{ formatCoins(friend.coins) }}
                            </p>
                        </div>
                    </div>
                    <div class="block-fren-cash">
                        <a><img src="@/assets/img/clicker.png" />+{{ friend.premium ? 0.5 : 0.05 }}</a>
                    </div>
                </div>
            </div>
            <div v-if="friends.length === 0">
                <div class="block-fren">
                    <div class="block-fren-img">
                        <img src="@/assets/img/plus.svg" />
                    </div>
                    <div class="block-fren-info">
                        <a>Invite your first friend</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="one-more-hud">
        <EnergyProgressComponent :current-energy="newEnergy" :total-energy="energyLimit" />
        <MenuComponent />
    </div>
</template>

<script>

import { Icon } from '@iconify/vue';

import axios from 'axios';
import NotificationComponent from '../components/NotificationComponent.vue';
import MenuComponent from '../components/MenuComponent.vue';

export default {
    name: 'FrensView',
    components: {
        NotificationComponent,
        MenuComponent,
        Icon
    },
    data() {
        return {
            friends: [],
            totalFriends: 0,
            userId: '',
            page: 1,
            isLoading: false,
            loadedUserIds: new Set(),
            bonusBlocks: [
                {
                    id: 1,
                    imgSrc: require('@/assets/img/gift.svg'),
                    title: 'Invite friend',
                    bonusText: '0.05 for you and your friend'
                },
                {
                    id: 2,
                    imgSrc: require('@/assets/img/gift-prem.svg'),
                    title: 'Invite friend with Telegram Premium',
                    bonusText: '0.5 for you and your friend'
                }
            ]
        };
    },
    async created() {
        await this.fetchUserData();
        // После успешного получения userId, вызываем fetchFriends
        if (this.userId) {
            await this.fetchFriends();
        }
    },
    methods: {
        async fetchUserData() {
            try {
                const response = await this.$axios.get('/home.php');
                if (response.data.error) {
                    console.error(response.data.error);
                    this.showNotification(response.data.error);
                } else {
                    this.userId = response.data.user_id;
                    sessionStorage.setItem('userId', this.userId);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                this.showNotification('Error fetching user data');
            }
        },
        async fetchFriends() {
            if (this.isLoading) return;
            this.isLoading = true;

            try {
                const response = await axios.get(`/load_friends.php`, {
                    params: {
                        r: this.userId, // Теперь userId будет установленным
                        page: this.page,
                    },
                });

                if (response.data.length > 0) {
                    response.data.forEach((friend) => {
                        if (!this.loadedUserIds.has(friend.tg_id)) {
                            this.friends.push(friend);
                            this.loadedUserIds.add(friend.tg_id);
                        }
                    });
                    this.page++;
                } else if (this.page === 1) {
                    this.friends.push({ username: 'Invite your first friend', tg_id: null });
                }

                this.totalFriends = this.friends.length;
            } catch (error) {
                console.error('Error loading friends:', error);
            } finally {
                this.isLoading = false;
            }
        },
        formatCoins(coins) {
            return Number(coins).toLocaleString('en', { minimumFractionDigits: 3 });
        },
        isValidPhotoUrl(url) {
            return url && /^https?:\/\//.test(url);
        },
        inviteFriend() {
            const inviteUrl = `https://t.me/share/url?url=https://t.me/rikcoinbot?start=ref_${this.userId}&text=Start playing with me, just click and earn real money%0A💸 0.05 coins as first gift%0A🔥0.5 coins if you have Telegram Premium`;
            window.open(inviteUrl, '_blank');
        },
        showNotification(message) {
            this.$refs.notification.show(message);
        },
        handleScroll() {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight - 100) {
                this.fetchFriends();
            }
        }
    },
    mounted() {
        document.body.style.overflowY = 'auto';
        this.userId = sessionStorage.getItem('userId');
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        document.body.style.overflowY = '';
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>
