import { createRouter, createWebHistory } from 'vue-router';
import IndexView from '../views/IndexView.vue';
import HomeView from '../views/HomeView.vue';
import FrensView from '../views/FrensView.vue';

const routes = [
    {
        path: '/',
        name: 'IndexView',
        component: IndexView
    },
    {
        path: '/home',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/frens',
        name: 'FrensView',
        component: FrensView
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
