/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="menu">
    <div class="eclipse-menu"></div>
    <button class="button" @click="navigateTo('home')">
      <img src="@/assets/img/menu-clicker.svg">
      <a>Clicker</a>
    </button>
    <button class="button" @click="navigateTo('frens')">
      <img src="@/assets/img/menu-frens.svg">
      <a>Frens</a>
    </button>
    <button class="button" @click="navigateTo('boosts')">
      <img src="@/assets/img/menu-boosts.svg">
      <a>Boosts</a>
    </button>
    <button class="button" @click="navigateTo('stats')">
      <img src="@/assets/img/menu-stats.svg">
      <a>Stats</a>
    </button>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  methods: {
    navigateTo(page) {
      this.$router.push(page)
    }
  }
}
</script>

